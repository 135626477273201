import React, { Component } from "react";
import { withRouter } from "react-router-dom";

export class Home extends Component {
  static displayName = Home.name;
    constructor(props) {
        super(props);

        
        const year = new Date().getFullYear() - 7;
        this.years = Array.from(new Array(8), (val, index) => index - year);
        this.months = Array.from(new Array(12), (val, index) => index + 1);
        this.days = Array.from(new Array(31), (val, index) => index + 1);
        this.state = {
            focus: "",
            programs: [],
            programName: "",
            parentFirstName: "",
            parentLastName: "",
            parentEmail: "",
            parentPhoneNumber: "",
            studentFirstName: "",
            studentLastName: "",
            studentGender: "",
            studentSchoolGrade: "",
            tshirtSize: "",
            emergencyFullName: "",
            emergencyRelationship: "",
            emergencyEmail: "",
            emergencyPhoneNumber: "",
            agreement: false,
            isClickedSubmitButton: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
  }

    async componentDidMount() {
        // Set remaining # availiability
        fetch(
            "/payment/holiday-programs",
            {
                method: "get",
                headers: {
                    AuthKey: "77e0D975$TbLb3dVbc6d2$ccda4G5b3bRda48880f",
                },
            }
        ).then(response => response.json())
        .then(data => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let autoSelectedProgramName = '';
            const programCode = urlParams.get('p');
            if (programCode) {
                let autoSelected = data.programs.find(x => x.programCode === programCode);
                if (autoSelected) {
                    autoSelectedProgramName = autoSelected.programName;
                }
            }
            this.setState({
                programs: data.programs,
                programName: autoSelectedProgramName
            });
        })
        .catch((error) => {
            console.error('Error:', error);
        });
        
    }

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    //this.updateSubmitButton();
  };
    handleInputChangeProgramSelect = (event) => {
        this.setState({ programName: event.target.value });
    }

    handleAgreementCheckbox = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }

    onCopyParentDetails = (e) => {
        if (e.target.checked) {
            this.setState({ emergencyFullName: this.state.parentFirstName + " " + this.state.parentLastName });
            this.setState({ emergencyEmail: this.state.parentEmail });
            this.setState({ emergencyPhoneNumber: this.state.parentPhoneNumber });
        } else {
            this.setState({ emergencyFullName: "" });
            this.setState({ emergencyEmail: "" });
            this.setState({ emergencyPhoneNumber: "" });
        }
    }

    updateSubmitButton() {
    return (
      this.state.programName.length > 0 &&
      this.state.parentFirstName.length > 0 &&
      this.state.parentLastName.length > 0 &&
      this.state.parentEmail.length > 0 &&
      this.state.parentPhoneNumber.length > 0 &&
      this.state.studentFirstName.length > 0 &&
      this.state.studentLastName.length > 0 &&
      this.state.studentGender.length > 0 &&
      this.state.studentSchoolGrade.length > 0 &&
      this.state.emergencyFullName.length > 0 &&
      this.state.emergencyRelationship.length > 0 &&
        this.state.emergencyEmail.length > 0 &&
        this.state.emergencyPhoneNumber.length > 0
    );
  }

  emailValdiate(email) {
    return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ isClickedSubmitButton: true });

    // if (
    //   !this.emailValdiate(this.state.parentEmail) ||
    //   !this.emailValdiate(this.state.emergencyEmail)
    // ) {
    //   alert("Please enter valid email address");
    //   return;
    // }

    if (this.updateSubmitButton()) {
      this.props.history.push({
        pathname: "/summary",
        state: this.state,
      });
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-8">
            <img
              className="pr-4 nvoke-img-height nvoke-logo-partner-padding"
              src="/images/nvoke_partner_logo.png"
            />
            <img
              className="nvoke-img-height nvoke-logo-future-padding"
              src="/images/nvoke_future_logo.png"
            />
          </div>
          <div className="col-md-4 text-bottom">
            <a href="https://nvokepartners.com/" target="_self">
              GO BACK TO NVOKE
            </a>
          </div>
        </div>
        <div className="form-container">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-lg-9 col-md-11 col-sm-11 col-xs-12">
                <div className="row">
                    <div className="col-12">
                        <label className="form-title pl-3">
                                        Nvoke Future Learning
                        </label>
                    </div>
                </div>
                <div className="col-12">
                                <label className="form-label-bold">Program details</label>
                                {this.state.programs.filter(x => x.isAvailable === false) && this.state.programs.filter(x => x.isAvailable === false).map((x, i) =>
                                    <div className="no-spots-remaining">No spots remaining for {x.programName}</div>
                                )}
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <select
                            name="programName"
                            className="form-control"
                            onChange={this.handleInputChangeProgramSelect}
                            onFocus={this.handleInputFocus}
                            value={this.state.programName}
                        >
                            <option value="">Select Option*</option>
                            {this.state.programs.map((x, i) =>
                                <option key={i} value={x.programName} disabled={x.isAvailable ? null : true}>
                                    {x.programName}
                                </option>
                            )}
                        </select>
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.programName.length === 0 ? (
                        <Results />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <label className="form-label-bold">
                    Parent/Carer details
                  </label>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="parentFirstName"
                          maxLength="30"
                          className="form-control"
                          placeholder="FIRST NAME*"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                        />
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.parentFirstName.length === 0 ? (
                        <Results />
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="parentLastName"
                          maxLength="30"
                          className="form-control"
                          placeholder="LAST NAME*"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                        />
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.parentLastName.length === 0 ? (
                        <Results />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <input
                          type="email"
                          name="parentEmail"
                          maxLength="150"
                          className="form-control"
                          placeholder="EMAIL*"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                        />
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.parentEmail.length === 0 ? (
                        <Results />
                      ) : null}
                      {this.state.isClickedSubmitButton &&
                      this.state.parentEmail.length > 0 &&
                      !this.emailValdiate(this.state.parentEmail) ? (
                        <EmailResults />
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <input
                          type="tel"
                          name="parentPhoneNumber"
                          maxLength="20"
                          className="form-control"
                          placeholder="PHONE NUMBER*"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                        />
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.parentPhoneNumber.length === 0 ? (
                        <Results />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <label className="form-label-bold">Student details</label>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="studentFirstName"
                          maxLength="30"
                          className="form-control"
                          placeholder="FIRST NAME*"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                        />
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.studentFirstName.length === 0 ? (
                        <Results />
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="studentLastName"
                          maxLength="30"
                          className="form-control"
                          placeholder="LAST NAME*"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                        />
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.studentLastName.length === 0 ? (
                        <Results />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <select
                          name="studentGender"
                          className="form-control"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          defaultValue=""
                        >
                          <option value="">GENDER*</option>
                          <option value="Female">Female</option>
                          <option value="Male">Male</option>
                          <option value="Non-binary">Non-binary</option>
                        </select>
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.studentGender.length === 0 ? (
                        <Results />
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <select
                          name="studentSchoolGrade"
                          className="form-control"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          defaultValue=""
                        >
                                                <option value="">SCHOOL GRADE*</option>
                                                <option value="Year 3">Year 3</option>
                                                <option value="Year 4">Year 4</option>
                          <option value="Year 5">Year 5</option>
                          <option value="Year 6">Year 6</option>
                                                <option value="Year 7">Year 7</option>
                                                <option value="Year 8">Year 8</option>
                                                <option value="Year 9">Year 9</option>
                                                <option value="Year 10">Year 10</option>
                                                <option value="Year 11">Year 11</option>
                                                <option value="Year 12">Year 12</option>
                        </select>
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.studentSchoolGrade.length === 0 ? (
                        <Results />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <label className="form-label-bold">
                    Select a t-shirt size to receive a free shirt on day 1 of the program
                  </label>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <select
                          name="tshirtSize"
                          className="form-control"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                          defaultValue=""
                        >
                                                <option value="">Select Option*</option>
                                                <option value="8">6</option>
                            <option value="8">8</option>
                          <option value="10">10</option>
                          <option value="12">12</option>
                                                <option value="14">14</option>
                                                <option value="16">16</option>
                                                <option value="16">18</option>
                        </select>
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.tshirtSize.length === 0 ? (
                        <Results />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                    <label className="form-label-bold">Emergency contact</label>
                    <div className="form-check">
                        <input
                            type="checkbox"
                            name="copyParentDetails"
                            className="form-check-input"
                            onChange={this.onCopyParentDetails}
                            id="copyParentDetails"
                        />
                        <label className="form-check-label form-label"
                                htmlFor="copyParentDetails">
                            same as parent/carer
                        </label>
                    </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <input
                                                type="text"
                                                name="emergencyFullName"
                                                maxLength="30"
                                                className="form-control"
                                                placeholder="FULL NAME*"
                                                onChange={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                                id="emergencyFullName"
                                                value={this.state.emergencyFullName}
                        />
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.emergencyFullName.length === 0 ? (
                        <Results />
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="emergencyRelationship"
                          maxLength="30"
                          className="form-control"
                          placeholder="RELATIONSHIP*"
                          onChange={this.handleInputChange}
                          onFocus={this.handleInputFocus}
                        />
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.emergencyRelationship.length === 0 ? (
                        <Results />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <input
                          type="email"
                          name="emergencyEmail"
                          maxLength="150"
                          className="form-control"
                          placeholder="EMAIL*"
                          onChange={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                                id="emergencyEmail"
                                                value={this.state.emergencyEmail}
                        />
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.emergencyEmail.length === 0 ? (
                        <Results />
                      ) : null}
                      {this.state.isClickedSubmitButton &&
                      this.state.emergencyEmail.length > 0 &&
                      !this.emailValdiate(this.state.emergencyEmail) ? (
                        <EmailResults />
                      ) : null}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <input
                          type="tel"
                          name="emergencyPhoneNumber"
                          maxLength="20"
                          className="form-control"
                          placeholder="PHONE NUMBER*"
                          onChange={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                                id="emergencyPhoneNumber"
                                                value={this.state.emergencyPhoneNumber}
                        />
                      </div>
                      {this.state.isClickedSubmitButton &&
                      this.state.emergencyPhoneNumber.length === 0 ? (
                        <Results />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <hr />
                </div>
                <div className="col-12">
                  <label className="form-label-bold">Disclaimer</label>
                </div>
                <div className="col-12 pt-3">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="agreement"
                      className="form-check-input"
                      onChange={this.handleAgreementCheckbox}
                      onFocus={this.handleInputFocus}
                      id="check2"
                    />
                    <label
                      className="form-check-label form-label"
                      htmlFor="check2"
                    >
                      I permit Nvoke Partners to take photos of my child during
                      the program for the purposes of documenting my child's
                      learning and/or promoting the program. Photos may appear
                      in print materials and on the world wide web via our
                      social media channels, website, and email. This consent is
                      optional; we respect the privacy of our students.
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 d-none d-lg-block d-md-block"></div>
            </div>

            <div className="row mb-5 pl-3">
              <div className="col-lg-4 col-md-8 col-sm-8 col-xs-8 pt-4 text-center">
                <button type="submit" className="btn Nvoke-button">
                  CONTINUE TO PAYMENT
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export class Results extends Component {
  render() {
    return <div className="valid-tip">The field is required.</div>;
  }
}

export class EmailResults extends Component {
  render() {
    return <div className="valid-tip">Please enter valid email address</div>;
  }
}
