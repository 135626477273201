import React, { Component } from "react";
import { Route } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import { FetchData } from "./components/FetchData";
import { Counter } from "./components/Counter";
import { Summary } from "./components/Summary";
import { Thanks } from "./components/Thanks";

import "./custom.css";
import "../node_modules/react-credit-cards/es/styles-compiled.css";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Route exact path="/" component={Home} />
        <Route path="/summary" component={Summary} />
        <Route path="/thanks" component={Thanks} />
      </Layout>
    );
  }
}
