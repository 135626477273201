import React, { Component } from "react";
import ReactDOM from "react-dom";
import InputMask from "react-input-mask";

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

export class Summary extends Component {
  static displayName = Summary.name;
  instance;
  static nonce22 = "";

  constructor(props) {
    super(props);
    this.state = {
      programName: props.location.state.programName,
      parentFirstName: props.location.state.parentFirstName,
      parentLastName: props.location.state.parentLastName,
      parentEmail: props.location.state.parentEmail,
      parentPhoneNumber: props.location.state.parentPhoneNumber,
      studentFirstName: props.location.state.studentFirstName,
      studentLastName: props.location.state.studentLastName,
      studentGender: props.location.state.studentGender,
      studentSchoolGrade: props.location.state.studentSchoolGrade,
      dayBirth: props.location.state.dayBirth,
      tshirtSize: props.location.state.tshirtSize,
      monthBirth: props.location.state.monthBirth,
      yearBirth: props.location.state.yearBirth,
      emergencyFullName: props.location.state.emergencyFullName,
      emergencyRelationship: props.location.state.emergencyRelationship,
      emergencyEmail: props.location.state.emergencyEmail,
        emergencyPhoneNumber: props.location.state.emergencyPhoneNumber,
      agreement: props.location.state.agreement,
      focus: "",
      clientToken: null,
      clientNonce: "",
      paymentTransactionId: null,
      isProcessing: false,
      nameOnCard: "",
      cardNumber: "",
      expiryMMYY: "",
      cvv: "",
      remainingCourseCount: "...",
      isClickedSubmitButton: false,
      HostedFieldsInstance: null,
      invalidCardNumber: false,
      invalidExpiryMMYY: false,
      invalidCvv: false,
      showBraintreeMessage: false,
      isAavaliableCourse: false,
      isEnableSubmitButton: true,
      showButtons: true,
      paid: false,
        registrationDateTime: null,
        programDate: "",
        programLocation: "",
        programTime: "",
        programFee: 0,
    };

    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
    this.updateSubmitButton();
  };

  async componentDidMount() {
    // Set remaining # availiability
      fetch(
          "/payment/holiday-programs",
          {
              method: "get",
              headers: {
                  AuthKey: "77e0D975$TbLb3dVbc6d2$ccda4G5b3bRda48880f",
              },
          }
      ).then(response => response.json())
          .then(data => {
              for (let i in data.programs) {
                  var programInfo = data.programs[i];
                  if (programInfo.programName === this.state.programName) {
                      this.setState({
                          remainingCourseCount: programInfo.totalSeats - programInfo.seatsTaken,
                          isAavaliableCourse: programInfo.isAvailable,
                          isEnableSubmitButton: programInfo.isAvailable,
                          programDate: programInfo.programDate,
                          programLocation: programInfo.programLocation,
                          programTime: programInfo.programTime,
                          programFee: programInfo.programFee,
                      });
                  }
              }
          })
          .catch((error) => {
              console.error('Error:', error);
          });
  }

  back() {
    this.props.history.replace("/");
  }

  createOrder = (data, actions) => {
    var payPalOrder = {
      purchase_units: [
        {
          description: this.props.location.state.programName,
          amount: {
            currency_code: "AUD",
              value: this.state.parentPhoneNumber === '1111111111' ? 0.1 : this.state.programFee,
          },
        },
      ],
    };
    return actions.order.create(payPalOrder);
  };

  onApprove = async (data, actions) => {
    let self = this;
    actions.order.capture().then(async (details) => {
      const paymentData = {
        payerID: data.payerID,
        orderID: data.orderID,
      };
      if (details.status === "COMPLETED") {
        this.setState({
          paymentTransactionId:
            details.purchase_units[0].payments.captures[0].id,
          registrationDateTime:
                details.purchase_units[0].payments.captures[0].create_time,
            isProcessing: true
        });

          var _body = JSON.stringify(this.state);
          fetch("/payment/sendemail", {
              method: "post",
              headers: {
                  "Content-Type": "application/json",
                  AuthKey: "77e0D975$TbLb3dVbc6d2$ccda4G5b3bRda48880f",
              },
              body: _body,
          }).then(response => response.json())
              .then(data => {
                  this.setState({
                      isProcessing: false
                  });

                  self.props.history.replace("/thanks");
              }).catch((error) => {
                  console.error('Error:', error);
              });;
      }
    });
  };

  render() {
    const { showButtons, paid } = this.state;

    return (
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-8">
            <img
              className="pr-4 nvoke-img-height nvoke-logo-partner-padding"
              src="/images/nvoke_partner_logo.png"
            />
            <img
              className="nvoke-img-height nvoke-logo-future-padding"
              src="/images/nvoke_future_logo.png"
            />
          </div>
          <div className="col-md-4 text-bottom">
            <a href="https://nvokepartners.com/" target="_self">
              {" "}
              GO BACK TO NVOKE
            </a>
          </div>
        </div>
        <div className="form-container">
          <div className="row">
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <div className="row">
                <div className="px-0 col-12 pb-2">
                                <label className="form-title">Nvoke Future Learning</label>
                </div>
              </div>
              <div className="px-0 col-lg-12 col-md-12 col-sm-12 col-xs-9 pb-2">
                            <label className="Nvoke-counter mx-auto">
                  There are {this.state.remainingCourseCount} spots remaining
                  for this course
                </label>
              </div>
              <div className="row">
                <div className="px-0 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <p>BOOKING SUMMARY</p>
                  <table className="table">
                    <thead className="thead-light">
                      <tr className="table-light">
                        <th scope="col">Program</th>
                        <th scope="col">{this.state.programName}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-light">
                        <th scope="row">Date</th>
                                            <td><div dangerouslySetInnerHTML={{ __html: this.state.programDate }} /></td>
                      </tr>
                      <tr className="table-light">
                        <th scope="row">Location</th>
                                            <td><div dangerouslySetInnerHTML={{ __html: this.state.programLocation }} /></td>
                      </tr>
                      <tr className="table-light">
                        <th scope="row">Time</th>
                                            <td><div dangerouslySetInnerHTML={{ __html: this.state.programTime }} /></td>
                      </tr>
                      <tr className="table-light">
                        <th scope="row">Total Price</th>
                                            <td>${this.state.programFee}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr></hr>
                </div>
              </div>
              {this.state.isProcessing && (
                <div className="row d-flex justify-content-center">
                  <div className="spinner-border text-center" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {this.state.showBraintreeMessage && <BraintreeResults />}
              <div className="row text-left">
                <div className="col-lg-3"></div>
                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12">
                  <button
                    type="Back"
                    className="btn btn-link Nvoke-link"
                    onClick={this.back.bind(this)}
                  >
                    Back
                  </button>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pr-0">
                  {showButtons && (
                    <div>
                      <PayPalButton
                        createOrder={(data, actions) =>
                          this.createOrder(data, actions)
                        }
                        onApprove={(data, actions) =>
                          this.onApprove(data, actions)
                        }
                      />
                    </div>
                  )}

                  {paid && (
                    <div className="main">
                      <h2>Thanks for your payment!</h2>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class Results extends Component {
  render() {
    return <div className="valid-tip">The field is required.</div>;
  }
}

export class InvalidResults extends Component {
  render() {
    return <div className="valid-tip">Please enter valid data</div>;
  }
}

export class BraintreeResults extends Component {
  render() {
    return (
      <div className="valid-tip">There was a problem with your request</div>
    );
  }
}
