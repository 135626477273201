import React, { Component } from "react";

export class Thanks extends Component {
  static displayName = Thanks.name;

  constructor(props) {
    super(props);
  }

  handleHome = e => {
    this.props.history.replace("/");
  };

  render() {
    return (
      <div className="container">
      <div className="row mt-3">
      <div className="col-md-8">
            <img className="pr-4 nvoke-img-height nvoke-logo-partner-padding" src="/images/nvoke_partner_logo.png"/>
            <img className="nvoke-img-height nvoke-logo-future-padding" src="/images/nvoke_future_logo.png" />
          </div>
        <div className="col-md-4 text-bottom">
          <a href="https://nvokepartners.com/" target="_self"> GO BACK TO NVOKE</a>
        </div>
      </div>
      <div className="form-container">
       <div className="row">
        <div className="col-lg-9 col-md-9 col-sm-10 col-xs-10">
            <div className="row">
              <div className="px-0 col-12 pb-2">
                <label className="form-title">Nvoke Future Learning</label>
              </div>
            </div>
            <div className="row">
              <div className="px-0 col-12">
                <hr></hr>
              <h2>THANK YOU</h2>
                                <p>Your payment and form submission has been successful. Please look out for an email with your booking summary details. For further enquiries, please contact Liz Bailey by email <a href="mailto:liz.bailey@nvokepartners.com">liz.bailey@nvokepartners.com</a>.</p>
              </div>
            </div>      
          </div>
        </div>
      </div>
    </div>
    );
  }
}
